import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import * as _ from 'lodash';
import customerService from 'services/customerService';
import { RootState } from 'store';
import { ICustomer, ISearchCustomer, Pagination, SearchResult } from 'types';
import { formatDate } from 'utils/helper';

interface CustomerState extends Pagination {
  customerList: ICustomer[];
  searchValues: ISearchCustomer;
}

const initialState: CustomerState = {
  page: 1,
  size: 10,
  total: 0,
  customerList: [],
  searchValues: {},
};

const customerSlice = createSlice({
  name: 'Customer',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<CustomerState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<CustomerState['size']>) => {
      state.size = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<CustomerState['searchValues']>) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchCustomerThunk.fulfilled, (state, action: PayloadAction<SearchResult<ICustomer[]>>) => {
      state.customerList = action.payload.result.map((item) => ({
        ...item,
        create_at: formatDate(item.createAt),
        custom_id: `C${item.customer_id}_B{item.bank_id}`,
        birthday_format: formatDate(item.birthday),
      }));
      state.total = action.payload.total;
    });
  },
});

export const searchCustomerThunk = createAsyncThunk(
  'searchCustomer',
  async (body: ISearchCustomer, { dispatch, getState }) => {
    const { customer } = getState() as RootState;
    const { data } = await customerService.searchAll(body, _.pick(customer, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const { setPage, setSize, setSearchValues } = customerSlice.actions;
const { reducer: customerReducer } = customerSlice;
export default customerReducer;
