import { ICreateLeague, ILeague, ISearchLeague, Pagination, SearchResult } from 'types';
import baseService from './baseService';

const API_END_POINT = '/league/';

const leagueService = {
  getAll: () => baseService.get<ILeague[]>(`${API_END_POINT}getAllV2`),
  search: (params: Pagination, body: ISearchLeague) => {
    return baseService.post<SearchResult<ILeague[]>>(
      `${API_END_POINT}getAll?page=${params.page}&size=${params.size}`,
      body
    );
  },
  create: (body: ICreateLeague) => baseService.post(`${API_END_POINT}`, body),
  update: (body: ICreateLeague, id: number) => baseService.put(`${API_END_POINT}${id}`, body),
  delete: (id: number) => baseService.delete(`${API_END_POINT}${id}`),
  getDetail: (id: number) => baseService.get<ILeague>(`${API_END_POINT}getById/${id}`),
};

export default leagueService;
