import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import permissionService from 'services/permissionService';
import { RootState } from 'store';
import { ActionType, ICreatePermission, IPermission, ISearchPermission, Pagination, SearchResult } from 'types';
import { formatDate, getLabelByValueStatus, showMessage } from 'utils/helper';
import * as _ from 'lodash';
import { PERMISSION_STATUS } from 'utils/constants';

interface PermissionState extends Pagination {
  permissionList: IPermission[];
  permissionDetail: IPermission;
  permissionAction: ActionType;
  showPermissionModal: boolean;
  searchValues: ISearchPermission;
}

const initialState: PermissionState = {
  page: 1,
  size: 10,
  total: 0,
  permissionList: [],
  permissionDetail: {} as IPermission,
  permissionAction: ActionType.create,
  showPermissionModal: false,
  searchValues: {},
};

const permissionSlice = createSlice({
  name: 'permission',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<PermissionState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<PermissionState['size']>) => {
      state.size = action.payload;
    },
    setPermissionAction: (state, action: PayloadAction<PermissionState['permissionAction']>) => {
      state.permissionAction = action.payload;
      state.showPermissionModal = true;
    },
    setShowPermissionModal: (state, action: PayloadAction<PermissionState['showPermissionModal']>) => {
      state.showPermissionModal = action.payload;
    },
    setPermissionDetail: (state, action: PayloadAction<PermissionState['permissionDetail']>) => {
      state.permissionDetail = action.payload;
      state.showPermissionModal = true;
      state.permissionAction = ActionType.update;
    },
    setSearchValues: (state, action: PayloadAction<PermissionState['searchValues']>) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchPermissionThunk.fulfilled, (state, action: PayloadAction<SearchResult<IPermission[]>>) => {
      state.permissionList = action.payload.result.map((item) => ({
        ...item,
        create_at: formatDate(item.createAt),
        statusText: getLabelByValueStatus(PERMISSION_STATUS, item.status),
      }));
      state.total = action.payload.total;
    });
  },
});

export const createPermissionThunk = createAsyncThunk(
  'createPermission',
  async (body: ICreatePermission, { dispatch, getState }) => {
    const { permission } = getState() as RootState;
    const response = await permissionService.create(body);
    showMessage.success(response.message);
    dispatch(searchPermissionThunk(permission.searchValues));
    return response.data;
  }
);

export const searchPermissionThunk = createAsyncThunk(
  'searchPermission',
  async (body: ISearchPermission, { dispatch, getState }) => {
    const { permission } = getState() as RootState;
    const { data } = await permissionService.search(body, _.pick(permission, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const updatePermissionThunk = createAsyncThunk(
  'updatePermission',
  async (body: ICreatePermission, { getState, dispatch }) => {
    const { permission } = getState() as RootState;
    const { data } = await permissionService.update(body, permission.permissionDetail.id);
    dispatch(searchPermissionThunk(permission.searchValues));
    return data;
  }
);

export const deletePermissionThunk = createAsyncThunk(
  'deletePermission',
  async (id: number, { dispatch, getState }) => {
    const { permission } = getState() as RootState;
    const { data } = await permissionService.delete(id);
    dispatch(searchPermissionThunk(permission.searchValues));
    return data;
  }
);

export const { setPage, setSize, setPermissionDetail, setSearchValues, setPermissionAction, setShowPermissionModal } =
  permissionSlice.actions;
const { reducer: permissionReducer } = permissionSlice;
export default permissionReducer;
