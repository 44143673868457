import { SearchResult, Pagination, IGroupPermission, ISearchGroupPermission, ICreateGroupPermission } from 'types';
import baseService from './baseService';

const API_END_POINT = '/group-role/';

const groupPermissionService = {
  getAll: (body: ISearchGroupPermission, params: Pagination) => {
    return baseService.post<SearchResult<IGroupPermission[]>>(`${API_END_POINT}search`, body, params);
  },
  create: (body: ICreateGroupPermission) =>
    baseService.post<ICreateGroupPermission, typeof body>(`${API_END_POINT}create`, body),
  update: (body: ICreateGroupPermission, id: number) =>
    baseService.put<ICreateGroupPermission, typeof body>(`${API_END_POINT}${id}`, body),
  delete: (id: number) => baseService.delete(`${API_END_POINT}${id}`),
  getDetail: (id: number) => baseService.get<IGroupPermission>(`${API_END_POINT}get-by-id/${id}`),
  getAllRoleByGroup: () => baseService.get(`${API_END_POINT}getAllRoleByGroup`),
};

export default groupPermissionService;
