import { ICustomer, ISearchCustomer, Pagination, SearchResult } from 'types';
import baseService from './baseService';

const API_END_POINT = '/customers';

const customerService = {
  searchAll: (body: ISearchCustomer, params: Pagination) =>
    baseService.post<SearchResult<ICustomer[]>>(`${API_END_POINT}/searchAll`, body, params),
  getDetail: (customerId: ICustomer['id']) => baseService.get(`${API_END_POINT}/profile/${customerId}`),

  getWithdrawRequest: (body, id: ICustomer['id']) => baseService.post(`/withdraw/filterByCustomer/${id}`, body),
  getBetsHistory: (body, params: Pagination) => baseService.post(`${API_END_POINT}/bet-history`, body, params),
  getPredictionEvent: (body, params: Pagination) => baseService.post(`${API_END_POINT}/prediction-event`, body, params),
};

export default customerService;
