import { SearchResult, IPermission, Pagination, ISearchPermission, ICreatePermission } from 'types';
import baseService from './baseService';

const API_END_POINT = '/role/';

const permissionService = {
  search: (body: ISearchPermission, params: Pagination) => {
    return baseService.post<SearchResult<IPermission[]>>(`${API_END_POINT}search`, body, params);
  },
  create: (body: ICreatePermission) => baseService.post<ICreatePermission>(`${API_END_POINT}create`, body),
  update: (body: ICreatePermission, id: number) => baseService.put<ICreatePermission>(`${API_END_POINT}${id}`, body),
  delete: (id: number) => baseService.delete(`${API_END_POINT}${id}`),
  getAll: () => baseService.get<IPermission[]>(`${API_END_POINT}getAll`),
};

export default permissionService;
