import { ITeam, ISearchTeam, Pagination, SearchResult } from 'types';
import baseService from './baseService';

const API_END_POINT = '/league-team';

const teamService = {
  getByLeagueId: (id: number) => baseService.get<ITeam[]>(`${API_END_POINT}/getTeamByLeague/${id}`),
  getAll: () => baseService.get<ITeam[]>('team/getAll'),
  searchAll: (body: ISearchTeam, params: Pagination) =>
    baseService.post<SearchResult<ITeam[]>>('team/searchAll', body, params),
  create: (body: ITeam) => baseService.post(`team/create`, body),
  update: (body: ITeam, id: ITeam['id']) => baseService.put(`team/update/${id}`, body),
  delete: (id: ITeam['id']) => baseService.delete(`team/delete/${id}`),
  getDetail: (id: ITeam['id']) => baseService.get(`team/getById/${id}`),
};

export default teamService;
