import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import eventService from 'services/eventService';
import { RootState } from 'store';
import { ICreateEvent, IEvent, IEventResult, ISearchEvent, Pagination, SearchResult } from 'types';
import { EVENT_STATUS } from 'utils/constants';
import { formatDateTime, getLabelByValueStatus, showMessage } from 'utils/helper';
import * as _ from 'lodash';
import imageService from 'services/imageService';
interface EventState extends Pagination {
  eventList: IEvent[];
  eventDetail: IEvent;
  eventResult: IEventResult;
  isLoading: boolean;
  searchValues: ISearchEvent;
}

const initialState: EventState = {
  eventList: [],
  eventDetail: {} as IEvent,
  page: 1,
  size: 10,
  total: 0,
  eventResult: {} as IEventResult,
  isLoading: false,
  searchValues: {},
};

const eventSlice = createSlice({
  name: 'event',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<EventState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<EventState['size']>) => {
      state.size = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<EventState['searchValues']>) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllEventsThunk.fulfilled, (state, action: PayloadAction<SearchResult<IEvent[]>>) => {
        const { result, total } = action.payload;
        state.total = total;
        state.eventList = result.map((event) => ({
          ...event,
          team_name: `${event.team1_name} - ${event.team2_name}`,
          status: getLabelByValueStatus(EVENT_STATUS, event.event_status),
          predict_time: formatDateTime(event.predict_deadline),
        }));
      })
      .addCase(getEventThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getEventThunk.fulfilled, (state, action: PayloadAction<IEvent>) => {
        state.eventDetail = action.payload;
        state.isLoading = false;
      })
      .addCase(getEventThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getEventResultThunk.fulfilled, (state, action: PayloadAction<IEventResult>) => {
        state.eventResult = action.payload;
      });
  },
});

export const getAllEventsThunk = createAsyncThunk(
  'getAllEvents',
  async (body: ISearchEvent, { dispatch, getState }) => {
    const { event } = getState() as RootState;
    const response = await eventService.getAll(body, _.pick(event, ['page', 'size']));
    dispatch(setSearchValues(body));
    return response.data;
  }
);

export const getEventThunk = createAsyncThunk('getEvent', async (id: number) => {
  const { data } = await eventService.get(id);
  const response = await imageService.getBase64(data[0]?.event_banner);
  data[0].event_banner = `data:image/jpeg;base64,${response.data.fileData}`;
  return data[0];
});

export const getEventResultThunk = createAsyncThunk('getEventResult', async (id: number) => {
  const response = await eventService.getResult(id);
  return response.data;
});

export const createEventThunk = createAsyncThunk('createEvent', async (body: ICreateEvent, { dispatch, getState }) => {
  const { event } = getState() as RootState;
  const response = await eventService.create(body);
  showMessage.success('successfully');
  dispatch(getAllEventsThunk(event.searchValues));
  return response;
});

export const { setPage, setSize, setSearchValues } = eventSlice.actions;
const { reducer: eventReducer } = eventSlice;
export default eventReducer;
