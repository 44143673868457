import { Spin } from 'antd';

const Loading = () => (
  <div
    style={{
      position: 'fixed',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      backgroundColor: 'rgba(0, 0, 0, 0.5)', // màu xám với độ mờ 0.5
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      zIndex: 99,
    }}
  >
    <Spin size="large" />
  </div>
);

export default Loading;
