import { IAuth, IAuthResponse } from 'types';
import baseService from './baseService';

const API_END_POINT = '/users/';

const authService = {
  login: (body: IAuth) => baseService.post<IAuthResponse>(`${API_END_POINT}signin`, body),
};

export default authService;
