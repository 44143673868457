import baseService from './baseService';

const API_END_POINT = '/files/';

const imageService = {
  upload: (body: FormData) => baseService.postFile<string>(`${API_END_POINT}upload`, body),
  download: () => baseService.downloadFile(`${API_END_POINT}getImage`),
  getBase64: (imageName: string | null) => baseService.get<any>(`${API_END_POINT}getImageS3/${imageName}`),
};

export default imageService;
