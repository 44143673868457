import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import dialService from 'services/dialService.ts';
import { ActionType, IBanner, Pagination } from 'types';

interface DialState extends Pagination {
  ticketsHT: IBanner[];
  ticketHTDetail: IBanner;
  ticketHTAction: ActionType;
  showTicketHTModal: boolean;
  searchValues: any;
  totalTicketCode?: number;
  isLoading: boolean;
  randomData?: any;
}

const initialState: DialState = {
  page: 1,
  size: 10,
  total: 0,
  totalTicketCode: 0,
  ticketsHT: [],
  ticketHTDetail: {} as IBanner,
  ticketHTAction: ActionType.create,
  showTicketHTModal: false,
  searchValues: {},
  isLoading: false,
  randomData: {},
};

const dialSlice = createSlice({
  name: 'Dial',
  initialState,
  reducers: {
    setSearchValues: (state: any, action: PayloadAction<any>) => {
      state.searchValues = action.payload;
    },
    setRandomValues: (state: any, action: PayloadAction<any>) => {
      state.randomData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchDialThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchDialThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchDialThunk.fulfilled, (state, action: any) => {
        state.ticketsHT = action.payload.result.map((item: any) => ({
          ...item,
        }));
        state.isLoading = false;
      })
      .addCase(dialThunk.fulfilled, (state, action: any) => {
        state.randomData = action.payload;
        state.isLoading = false;
      });
  },
});

export const searchDialThunk = createAsyncThunk('searchDial', async (_: any, { dispatch }) => {
  const { data } = await dialService.search();
  dispatch(setSearchValues());
  return data;
});

export const dialThunk = createAsyncThunk('dial', async () => {
  const { data } = await dialService.dial();
  return data;
});

export const { setSearchValues, setRandomValues }: any = dialSlice.actions;
const { reducer: ticketHT } = dialSlice;
export default ticketHT;
