import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import leagueService from 'services/leagueService';
import { RootState } from 'store';
import { ActionType, ICreateLeague, ILeague, ISearchLeague, Pagination, SearchResult } from 'types';
import * as _ from 'lodash';
import { formatDateTime, showMessage } from 'utils/helper';
import imageService from 'services/imageService';

interface LeagueState extends Pagination {
  leagueList: ILeague[];
  allLeagueList: ILeague[];
  leagueDetail: ILeague;
  leagueAction: ActionType;
  showLeagueModal: boolean;
  isLoading: boolean;
  searchValues: ISearchLeague;
}

const initialState: LeagueState = {
  leagueList: [],
  allLeagueList: [],
  page: 1,
  size: 10,
  total: 0,
  leagueAction: ActionType.create,
  leagueDetail: {} as ILeague,
  showLeagueModal: false,
  isLoading: false,
  searchValues: {},
};

const leagueSlice = createSlice({
  name: 'league',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<LeagueState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<LeagueState['size']>) => {
      state.size = action.payload;
    },
    setLeagueAction: (state, action: PayloadAction<LeagueState['leagueAction']>) => {
      state.leagueAction = action.payload;
      state.showLeagueModal = true;
    },
    setShowLeagueModal: (state, action: PayloadAction<LeagueState['showLeagueModal']>) => {
      state.showLeagueModal = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<LeagueState['searchValues']>) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAllLeaguesThunk.fulfilled, (state, action: PayloadAction<ILeague[]>) => {
        state.allLeagueList = action.payload;
      })
      .addCase(searchLeaguesThunk.fulfilled, (state, action: PayloadAction<SearchResult<ILeague[]>>) => {
        state.leagueList = action.payload.result.map((league) => ({
          ...league,
          predict: `${formatDateTime(league.start_time)} - ${formatDateTime(league.end_time)}`,
        }));
        state.total = action.payload.total;
      })
      .addCase(getLeagueDetailThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getLeagueDetailThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getLeagueDetailThunk.fulfilled, (state, action: PayloadAction<ILeague>) => {
        state.leagueDetail = action.payload;
        state.isLoading = false;
      });
  },
});

export const getAllLeaguesThunk = createAsyncThunk('getAllLeagues', async () => {
  const response = await leagueService.getAll();
  return response.data;
});

export const createLeagueThunk = createAsyncThunk(
  'createLeague',
  async (body: ICreateLeague, { dispatch, getState }) => {
    const { league } = getState() as RootState;
    const response = await leagueService.create(body);
    showMessage.success(response.message);
    dispatch(searchLeaguesThunk(league.searchValues));
    return response.data;
  }
);

export const getLeagueDetailThunk = createAsyncThunk('getLeagueDetail', async (id: number, { dispatch }) => {
  const { data } = await leagueService.getDetail(id);
  const response = await imageService.getBase64(data?.league_image);
  data.league_image_src = `data:image/jpeg;base64,${response.data.fileData}`;
  dispatch(setShowLeagueModal(true));
  dispatch(setLeagueAction(ActionType.update));
  return data;
});

export const updateLeagueThunk = createAsyncThunk(
  'updateLeague',
  async (body: ICreateLeague, { getState, dispatch }) => {
    const { league } = getState() as RootState;
    const { data } = await leagueService.update(body, league.leagueDetail.id);
    dispatch(searchLeaguesThunk(league.searchValues));
    dispatch(setShowLeagueModal(false));
    return data;
  }
);

export const deletLeagueThunk = createAsyncThunk('deletLeague', async (id: number, { dispatch, getState }) => {
  const { league } = getState() as RootState;
  const { data } = await leagueService.delete(id);
  dispatch(searchLeaguesThunk(league.searchValues));
  return data;
});

export const searchLeaguesThunk = createAsyncThunk(
  'searchAllLeagues',
  async (body: ISearchLeague, { dispatch, getState }) => {
    const { league } = getState() as RootState;
    const response = await leagueService.search(_.pick(league, ['page', 'size']), body);
    dispatch(setSearchValues(body));
    return response.data;
  }
);

export const { setPage, setSize, setLeagueAction, setShowLeagueModal, setSearchValues } = leagueSlice.actions;
const { reducer: leagueReducer } = leagueSlice;
export default leagueReducer;
