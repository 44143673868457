import { ToastOptions } from 'react-toastify';
import {
  BetStatus,
  Betting1X2Type,
  BettingHandicapType,
  BettingOverUnderType,
  EventStatus,
  Method,
  PermissionStatus,
  WithdrawalStatus,
  TeamStatus,
  BetType,
} from 'types';

export const STATUS_API_ERROR = 0;
export const STATUS_UNKNOWN_ERROR = 5;
export const API_SUCCESS_STATUS = 200;
export const UNKNOWN_ERROR_MESSAGE = 'Có lỗi hệ thống xảy ra!';

export const LOCAL_STORAGE = {
  AUTH_INFO: '@currentAuth',
  ACCESS_TOKEN: '@accessToken',
};

export const TOAST_OPTIONS: ToastOptions = {
  theme: 'colored',
  pauseOnFocusLoss: false,
  autoClose: 2000,
  toastId: 401,
};

export const DATE_FORMAT = 'DD/MM/YYYY';
export const DATE_TIME_FORMAT = 'DD/MM/YYYY - HH:mm';
export const PREDICT_TIME = 'DD/MM/YYYY hh:mm:ss - DD/MM/YYYY hh:mm:ss';

export const EVENT_STATUS = [
  { label: 'Pending', value: EventStatus.PENDING },
  { label: 'Active', value: EventStatus.ACTIVE },
  { label: 'End', value: EventStatus.END },
  { label: 'Rejected', value: EventStatus.REJECTED },
];

export const BET_STATUS = [
  { label: 'Approved', value: BetStatus.ACTIVE },
  { label: 'Pending', value: BetStatus.PENDING },
  { label: 'Rejected', value: BetStatus.REJECTED },
];

export const BETTING_1X2_TYPE = [
  { label: 'Full time', value: Betting1X2Type.FULL_TIME },
  { label: 'Half time', value: Betting1X2Type.HALF_TIME },
];

export const BETTING_HANDICAP_TYPE = [
  { label: 'Corner kick full time', value: BettingHandicapType.CORNER_KICK_FULL_TIME },
  { label: 'Corner kick half time', value: BettingHandicapType.CORNER_KICK_HALF_TIME },
  { label: 'Handicap full time', value: BettingHandicapType.HANDICAP_FULL_TIME },
  { label: 'Handicap half time', value: BettingHandicapType.HANDICAP_HALF_TIME },
  { label: 'Penalty card full time', value: BettingHandicapType.PENALTY_CARD_FULL_TIME },
  { label: 'Penalty card half time', value: BettingHandicapType.PENALTY_CARD_HALF_TIME },
];

export const BETTING_OVERUNDER_TYPE = [
  { label: 'Total goals full time', value: BettingOverUnderType.TOTAL_GOALS_FULL_TIME },
  { label: 'Total goals half time', value: BettingOverUnderType.TOTAL_GOALS_HALF_TIME },
  { label: 'Total corner kick full time', value: BettingOverUnderType.TOTAL_CORNER_KICK_FULL_TIME },
  { label: 'Total corner kick half time', value: BettingOverUnderType.TOTAL_CORNER_KICK_HALF_TIME },
  { label: 'Total Penalty card full time', value: BettingOverUnderType.TOTAL_PENALTY_CARD_FULL_TIME },
  { label: 'Total Penalty card half time', value: BettingOverUnderType.TOTAL_PENALTY_CARD_HALF_TIME },
];

export const EVENT_RESULT_TYPE = ['score', 'corner', 'yellowCard', 'redCard'];

export const PERMISSION_STATUS = [
  { label: 'Inactive', value: PermissionStatus.INACTIVE },
  { label: 'Active', value: PermissionStatus.ACTIVE },
];

export const METHOD_LIST = [
  { label: 'Get', value: Method.GET },
  { label: 'Post', value: Method.POST },
  { label: 'Put', value: Method.PUT },
  { label: 'Delete', value: Method.DELETE },
];

export const WITHDRAWAL_STATUS = [
  { label: 'Waiting', value: WithdrawalStatus.PENDING },
  { label: 'Approved', value: WithdrawalStatus.APPROVED },
  { label: 'Rejected', value: WithdrawalStatus.REJECTED },
];

export const Team_STATUS = [
  { label: 'Inactive', value: TeamStatus.INACTIVE },
  { label: 'Active', value: TeamStatus.ACTIVE },
];

export const CUSTOMER_STATUS = [
  { label: 'Inactive', value: TeamStatus.INACTIVE },
  { label: 'Active', value: TeamStatus.ACTIVE },
];

export const TIME_OPTIONS = [
  { label: 'Day', value: 'date' },
  { label: 'Month', value: 'month' },
  { label: 'Year', value: 'year' },
];

export const BET_TYPE = [
  { label: '1 x 2', value: BetType.WDL },
  { label: 'Handicap', value: BetType.HANDICAP },
  { label: 'Over/Under', value: BetType.OVERUNDER },
  { label: 'Yes/No', value: BetType.YESNO },
];
