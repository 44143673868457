import { IAdminAccount, ICreateAdminAccount, ISearchAdminAccount, Pagination, SearchResult } from 'types';
import baseService from './baseService';

const API_END_POINT = '/admin-account/';

const adminAccountService = {
  search: (body: ISearchAdminAccount, params: Pagination) =>
    baseService.post<SearchResult<IAdminAccount[]>>(`${API_END_POINT}searchAll`, body, params),
  create: (body: ICreateAdminAccount) => baseService.post(`${API_END_POINT}create`, body),
  update: (body: ICreateAdminAccount, id: IAdminAccount['id']) => baseService.put(`${API_END_POINT}update/${id}`, body),
  delete: (id: IAdminAccount['id']) => baseService.delete(`${API_END_POINT}delete/${id}`),
  getDetail: (id: IAdminAccount['id']) => baseService.get(`${API_END_POINT}getById/${id}`),
};

export default adminAccountService;
