import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import withdrawalService from 'services/withdrawalService';
import { RootState } from 'store';
import { IWithdrawal, ISearchWithdrawal, Pagination, SearchResult, WithdrawalUpdateParams } from 'types';
import { WITHDRAWAL_STATUS } from 'utils/constants';
import { formatDateTime, getLabelByValueStatus } from 'utils/helper';
import * as _ from 'lodash';

interface WithdrawalState extends Pagination {
  withdrawalList: IWithdrawal[];
  searchValues: ISearchWithdrawal;
}

const initialState: WithdrawalState = {
  page: 1,
  size: 10,
  total: 0,
  withdrawalList: [],
  searchValues: {},
};

const withdrawalSlice = createSlice({
  name: 'withdrawal',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<WithdrawalState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<WithdrawalState['size']>) => {
      state.size = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<WithdrawalState['searchValues']>) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchWithdrawalThunk.fulfilled, (state, action: PayloadAction<SearchResult<IWithdrawal[]>>) => {
      state.withdrawalList = action.payload.result.map((item) => ({
        ...item,
        create_at: formatDateTime(item.createAt),
        update_at: formatDateTime(item.updateAt),
        withdrawal_status: getLabelByValueStatus(WITHDRAWAL_STATUS, item.current_status),
      }));
      state.total = action.payload.total;
    });
  },
});

export const searchWithdrawalThunk = createAsyncThunk(
  'searchWithdrawal',
  async (body: ISearchWithdrawal, { dispatch, getState }) => {
    const { withdrawal } = getState() as RootState;
    const { data } = await withdrawalService.search(body, _.pick(withdrawal, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const updateWithdrawalThunk = createAsyncThunk(
  'updateWithdrawal',
  async (params: WithdrawalUpdateParams, { dispatch, getState }) => {
    const { withdrawal } = getState() as RootState;
    const { data } = await withdrawalService.update(params);
    dispatch(searchWithdrawalThunk(withdrawal.searchValues));
    return data;
  }
);

export const { setPage, setSize, setSearchValues } = withdrawalSlice.actions;
const { reducer: withdrawalReducer } = withdrawalSlice;
export default withdrawalReducer;
