import { ConfigProviderProps } from 'antd/es/config-provider';
import form from './form';
import locale from './locale';
import theme from './theme';

const configs: ConfigProviderProps = {
  form,
  locale,
  theme,
};

export default configs;
