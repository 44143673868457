import { combineReducers } from '@reduxjs/toolkit';
import authReducer from 'slices/authSlice';
import eventReducer from 'slices/eventSlice';
import leagueReducer from 'slices/leagueSlice';
import permissionReducer from 'slices/permissionSlice';
import groupPermissionReducer from 'slices/groupPermissionSlice';
import adminAccountReducer from 'slices/adminAccountSlice';
import bannerReducer from 'slices/bannerSlice';
import withdrawalReducer from 'slices/withdrawalSlice';
import teamReducer from 'slices/teamSlice';
import customerReducer from 'slices/customerSlice';
import ticketHTReducer from 'slices/ticketHTSlice';
import dialReducer from 'slices/dialSlice';

const rootReducer = combineReducers({
  auth: authReducer,
  event: eventReducer,
  league: leagueReducer,
  permission: permissionReducer,
  groupPermission: groupPermissionReducer,
  adminAccount: adminAccountReducer,
  banner: bannerReducer,
  withdrawal: withdrawalReducer,
  team: teamReducer,
  customer: customerReducer,
  ticketHT: ticketHTReducer,
  dial: dialReducer,
});

export default rootReducer;
