import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ActionType, ICreateBanner, IBanner, ISearchBanner, Pagination, SearchResult } from 'types';
import { formatDate, showMessage } from 'utils/helper';
import * as _ from 'lodash';
import ticketHTService from 'services/ticketHTService';

interface TicketHTState extends Pagination {
  ticketsHT: IBanner[];
  ticketHTDetail: IBanner;
  ticketHTAction: ActionType;
  showTicketHTModal: boolean;
  searchValues: ISearchBanner;
  totalTicketCode?: number;
  isLoading: boolean;
}

const initialState: TicketHTState = {
  page: 1,
  size: 10,
  total: 0,
  totalTicketCode: 0,
  ticketsHT: [],
  ticketHTDetail: {} as IBanner,
  ticketHTAction: ActionType.create,
  showTicketHTModal: false,
  searchValues: {},
  isLoading: false,
};

const ticketHTSlice = createSlice({
  name: 'TicketHT',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<TicketHTState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<TicketHTState['size']>) => {
      state.size = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<TicketHTState['searchValues']>) => {
      state.searchValues = action.payload;
    },
    setTicketHTAction: (state, action: PayloadAction<TicketHTState['ticketHTAction']>) => {
      state.ticketHTAction = action.payload;
      state.showTicketHTModal = true;
    },
    setShowTicketHTModal: (state, action: PayloadAction<TicketHTState['showTicketHTModal']>) => {
      state.showTicketHTModal = action.payload;
    },
    setTicketHTDetail: (state, action: PayloadAction<TicketHTState['ticketHTDetail']>) => {
      state.ticketHTDetail = action.payload;
      state.showTicketHTModal = true;
      state.ticketHTAction = ActionType.update;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchTicketHTThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(searchTicketHTThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(searchTicketHTThunk.fulfilled, (state, action: PayloadAction<SearchResult<IBanner[]>>) => {
        state.ticketsHT = action.payload.result.map((item) => ({
          ...item,
          create_at: formatDate(item.createAt),
          customerImageSrc: `${process.env.REACT_APP_API_URL}/public-file/getImage/${item.customerImage}`,
        }));
        state.total = action.payload.total;
        state.totalTicketCode = action.payload.totalTicketCode;
        state.isLoading = false;
      })
      .addCase(createTicketHTThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(createTicketHTThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(createTicketHTThunk.fulfilled, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const createTicketHTThunk = createAsyncThunk(
  'createTicketHT',
  async (body: ICreateBanner, { dispatch, getState }) => {
    const { ticketHT } = getState() as RootState;
    const response = await ticketHTService.create(body);
    showMessage.success('Tạo mới vé thành công');
    dispatch(searchTicketHTThunk(ticketHT.searchValues));
    return response.data;
  }
);

export const searchTicketHTThunk = createAsyncThunk(
  'searchTicketHT',
  async (body: ISearchBanner, { dispatch, getState }) => {
    const { ticketHT } = getState() as RootState;
    const { data } = await ticketHTService.search(body, _.pick(ticketHT, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const updateBannerThunk = createAsyncThunk(
  'updateTicketHT',
  async (body: ICreateBanner, { getState, dispatch }) => {
    const { ticketHT } = getState() as RootState;
    const { data } = await ticketHTService.update(body, ticketHT.ticketHTDetail.id);
    dispatch(searchTicketHTThunk(ticketHT.searchValues));
    return data;
  }
);

export const deleteTicketHTThunk = createAsyncThunk('deleteTicketHT', async (id: number, { dispatch, getState }) => {
  const { ticketHT } = getState() as RootState;
  const { data } = await ticketHTService.delete(id);
  dispatch(searchTicketHTThunk(ticketHT.searchValues));
  showMessage.success('Xóa thành công!');
  return data;
});

export const downloadExcelThunk = createAsyncThunk('downloadExcel', async () => {
  try {
    const response = await fetch(`${process.env.REACT_APP_API_URL}excel/download-htt`, {
      method: 'GET',
    });

    // Kiểm tra nếu response trả về thành công
    if (!response.ok) {
      throw new Error('Failed to download file');
    }

    // Chuyển đổi phản hồi thành Blob (dữ liệu file)
    const blob = await response.blob();

    // Tạo URL tạm thời cho Blob
    const url = window.URL.createObjectURL(blob);

    // Tạo thẻ <a> để kích hoạt tải file
    const a = document.createElement('a');
    a.href = url;
    a.download = 'file_report.xlsx'; // Tên file sẽ được tải xuống
    a.click(); // Kích hoạt việc tải xuống file

    // Giải phóng URL sau khi tải xong
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
    showMessage.error('Có lỗi xảy ra rồi!');
  }
});

export const { setPage, setSize, setTicketHTDetail, setTicketHTAction, setShowTicketHTModal, setSearchValues } =
  ticketHTSlice.actions;
const { reducer: ticketHT } = ticketHTSlice;
export default ticketHT;
