import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import { ActionType, ITeam, ISearchTeam, Pagination, SearchResult } from 'types';
import { formatDate, showMessage, getLabelByValueStatus } from 'utils/helper';
import * as _ from 'lodash';
import teamService from 'services/teamService';
import { PERMISSION_STATUS } from 'utils/constants';

interface TeamState extends Pagination {
  teamList: ITeam[];
  teamDetail: ITeam;
  teamAction: ActionType;
  showTeamModal: boolean;
  searchValues: ISearchTeam;
}

const initialState: TeamState = {
  page: 1,
  size: 10,
  total: 0,
  teamList: [],
  teamDetail: {} as ITeam,
  teamAction: ActionType.create,
  searchValues: {},
  showTeamModal: false,
};

const teamSlice = createSlice({
  name: 'Team',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<TeamState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<TeamState['size']>) => {
      state.size = action.payload;
    },
    setTeamAction: (state, action: PayloadAction<TeamState['teamAction']>) => {
      state.teamAction = action.payload;
      state.showTeamModal = true;
    },
    setShowTeamModal: (state, action: PayloadAction<TeamState['showTeamModal']>) => {
      state.showTeamModal = action.payload;
    },
    setTeamDetail: (state, action: PayloadAction<TeamState['teamDetail']>) => {
      state.teamDetail = action.payload;
      state.showTeamModal = true;
      state.teamAction = ActionType.update;
    },
    setSearchValues: (state, action: PayloadAction<TeamState['searchValues']>) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(searchTeamThunk.fulfilled, (state, action: PayloadAction<SearchResult<ITeam[]>>) => {
      state.teamList = action.payload.result.map((item) => ({
        ...item,
        create_at: formatDate(item.createAt),
        team_img_src: `${process.env.REACT_APP_API_URL}/public-file/getImage/${item.team_image}`,
        statusText: getLabelByValueStatus(PERMISSION_STATUS, item.status),
      }));
      state.total = action.payload.total;
    });
  },
});

export const createTeamThunk = createAsyncThunk('createTeam', async (body: ITeam, { dispatch, getState }) => {
  const { team } = getState() as RootState;
  const response = await teamService.create(body);
  dispatch(searchTeamThunk(team.searchValues));
  showMessage.success(response.message);
  return response.data;
});

export const searchTeamThunk = createAsyncThunk('searchTeam', async (body: ISearchTeam, { dispatch, getState }) => {
  const { team } = getState() as RootState;
  const { data } = await teamService.searchAll(body, _.pick(team, ['page', 'size']));
  dispatch(setSearchValues(body));
  return data;
});

export const updateTeamThunk = createAsyncThunk('updateTeam', async (body: ITeam, { getState, dispatch }) => {
  const { team } = getState() as RootState;
  const { data } = await teamService.update(body, team.teamDetail.id);
  dispatch(searchTeamThunk(team.searchValues));
  return data;
});

export const deleteTeamThunk = createAsyncThunk('deleteTeam', async (id: number, { dispatch, getState }) => {
  const { team } = getState() as RootState;
  const { data } = await teamService.delete(id);
  dispatch(searchTeamThunk(team.searchValues));
  return data;
});

export const { setPage, setSize, setTeamDetail, setTeamAction, setShowTeamModal, setSearchValues } = teamSlice.actions;
const { reducer: teamReducer } = teamSlice;
export default teamReducer;
