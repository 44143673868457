import { RcFile } from 'antd/es/upload';
import dayjs from 'dayjs';
import { toast } from 'react-toastify';
import { Status } from 'types';
import { DATE_FORMAT, DATE_TIME_FORMAT, TOAST_OPTIONS } from './constants';

export function isNotNullOrUndefined<T>(data: T) {
  return data !== null && data !== undefined;
}

export const formatDate = (date?: Date | number) => {
  return date && dayjs(date).format(DATE_FORMAT);
};

export const formatDateTime = (date?: Date | number) => {
  return date && dayjs(date).format(DATE_TIME_FORMAT);
};

export const formatToTimestamps = (date?: Date | number) => {
  return date && dayjs(date).valueOf();
};

export const showMessage = {
  success: (message: string = 'successfully') => toast.success(message, TOAST_OPTIONS),
  error: (message: string = 'failed') => toast.error(message, TOAST_OPTIONS),
  info: (message: string) => toast.info(message, TOAST_OPTIONS),
  warning: (message: string) => toast.warning(message, TOAST_OPTIONS),
};

export const getNameByValue = <T = any>(array: Array<T>, key: keyof T, value: string | number, name: string) => {
  const found = array.find((item) => item[key] === value);
  return found ? found[name] : null;
};

// for event status, betting type, ..
export const getLabelByValueStatus = (array: Status[], value: number) => {
  return array.find((item) => item.value === value)?.label;
};

export const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });
