import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import authService from 'services/authService';
import { IAuth } from 'types';
import { LOCAL_STORAGE } from 'utils/constants';
import { showMessage } from 'utils/helper';

interface AuthState {
  isLoading: boolean;
}

const initialState: AuthState = {
  isLoading: false,
};

const authSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(loginThunk.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(loginThunk.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(loginThunk.fulfilled, (state, action: PayloadAction<any>) => {
        const data = action.payload;
        localStorage.setItem(LOCAL_STORAGE.ACCESS_TOKEN, JSON.stringify(data.accessToken));
        localStorage.setItem(LOCAL_STORAGE.AUTH_INFO, JSON.stringify(data.user));
        showMessage.success('Đăng nhập thành công');
        state.isLoading = false;
      });
  },
});

export const loginThunk = createAsyncThunk('login', async (params: IAuth) => {
  const response = await authService.login(params);
  return response;
});

const { reducer: authReducer } = authSlice;
export default authReducer;
