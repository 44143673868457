import { lazy } from 'react';
import Loadable from 'utils/Loadable';
import type { RouteObject } from 'react-router-dom';
import { path } from './path';

const CustomerPage = Loadable(lazy(() => import('containers/Customer')));
const CustomerDetailPage = Loadable(lazy(() => import('containers/Customer/CustomerDetail')));

const CustomerRoutes: RouteObject[] = [
  {
    path: path.customer,
    element: <CustomerPage />,
  },
  {
    path: path.customerDetail,
    element: <CustomerDetailPage />,
  },
];

export default CustomerRoutes;
