import { ConfigProviderProps } from 'antd/es/config-provider';

const locale: ConfigProviderProps['locale'] = {
  locale: '',
  Modal: {
    okText: 'Save',
    cancelText: 'Cancel',
    justOkText: 'Save',
  },
};

export default locale;
