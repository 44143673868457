import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import adminAccountService from 'services/adminAccountService';
import { RootState } from 'store';
import { ActionType, IAdminAccount, ICreateAdminAccount, ISearchAdminAccount, Pagination, SearchResult } from 'types';
import { formatDate, showMessage } from 'utils/helper';
import * as _ from 'lodash';

interface AdminAccountState extends Pagination {
  adminAccountList: IAdminAccount[];
  adminAccountDetail: IAdminAccount;
  adminAccountAction: ActionType;
  showAdminAccountModal: boolean;
  searchValues: ISearchAdminAccount;
}

const initialState: AdminAccountState = {
  page: 1,
  size: 10,
  total: 0,
  adminAccountList: [],
  adminAccountDetail: {} as IAdminAccount,
  adminAccountAction: ActionType.create,
  showAdminAccountModal: false,
  searchValues: {},
};

const adminAccountSlice = createSlice({
  name: 'adminAccount',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<AdminAccountState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<AdminAccountState['size']>) => {
      state.size = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<AdminAccountState['searchValues']>) => {
      state.searchValues = action.payload;
    },
    setAdminAccountAction: (state, action: PayloadAction<AdminAccountState['adminAccountAction']>) => {
      state.adminAccountAction = action.payload;
      state.showAdminAccountModal = true;
    },
    setShowAdminAccountModal: (state, action: PayloadAction<AdminAccountState['showAdminAccountModal']>) => {
      state.showAdminAccountModal = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(searchAdminAccountThunk.fulfilled, (state, action: PayloadAction<SearchResult<IAdminAccount[]>>) => {
        state.adminAccountList = action.payload.result.map((item) => ({
          ...item,
          create_at: formatDate(item.createAt),
        }));
        state.total = action.payload.total;
      })
      .addCase(
        getAdminAccountThunk.fulfilled,
        (state, action: PayloadAction<AdminAccountState['adminAccountDetail']>) => {
          state.adminAccountDetail = action.payload;
          state.showAdminAccountModal = true;
          state.adminAccountAction = ActionType.update;
        }
      );
  },
});

export const createAdminAccountThunk = createAsyncThunk(
  'createadminAccount',
  async (body: ICreateAdminAccount, { dispatch, getState }) => {
    const { adminAccount } = getState() as RootState;
    const response = await adminAccountService.create(body);
    showMessage.success(response.message);
    dispatch(searchAdminAccountThunk(adminAccount.searchValues));
    dispatch(setShowAdminAccountModal(false));
    return response.data;
  }
);

export const searchAdminAccountThunk = createAsyncThunk(
  'searchadminAccount',
  async (body: ISearchAdminAccount, { dispatch, getState }) => {
    const { adminAccount } = getState() as RootState;
    const { data } = await adminAccountService.search(body, _.pick(adminAccount, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const updateAdminAccountThunk = createAsyncThunk(
  'updateadminAccount',
  async (body: ICreateAdminAccount, { getState, dispatch }) => {
    const { adminAccount } = getState() as RootState;
    const { data } = await adminAccountService.update(body, adminAccount.adminAccountDetail.id);
    dispatch(searchAdminAccountThunk(adminAccount.searchValues));
    dispatch(setShowAdminAccountModal(false));
    return data;
  }
);

export const deleteAdminAccountThunk = createAsyncThunk(
  'deleteAdminAccount',
  async (id: number, { dispatch, getState }) => {
    const { adminAccount } = getState() as RootState;
    const { data } = await adminAccountService.delete(id);
    dispatch(searchAdminAccountThunk(adminAccount.searchValues));
    return data;
  }
);

export const getAdminAccountThunk = createAsyncThunk('getAdminAccount', async (id: number, { dispatch }) => {
  const { data } = await adminAccountService.getDetail(id);
  return data;
});

export const { setPage, setSize, setAdminAccountAction, setShowAdminAccountModal, setSearchValues } =
  adminAccountSlice.actions;
const { reducer: adminAccountReducer } = adminAccountSlice;
export default adminAccountReducer;
