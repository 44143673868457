import { lazy } from 'react';
import Loadable from 'utils/Loadable';
import type { RouteObject } from 'react-router-dom';
import { path } from './path';

const EventPage = Loadable(lazy(() => import('containers/Event')));
const EventDetailPage = Loadable(lazy(() => import('containers/Event/EventDetail')));

const EventRoutes: RouteObject[] = [
  {
    path: path.event,
    element: <EventPage />,
  },
  {
    path: path.eventDetail,
    element: <EventDetailPage />,
  },
];

export default EventRoutes;
