import { IWithdrawal, ISearchWithdrawal, SearchResult, WithdrawalUpdateParams, Pagination } from 'types';
import baseService from './baseService';

const API_END_POINT = '/withdraw';

const withdrawalService = {
  update: (params: WithdrawalUpdateParams) => baseService.put(`${API_END_POINT}/confirm-withdraw-request`, {}, params),
  getAll: (status: number = 0) =>
    baseService.get<SearchResult<IWithdrawal[]>>(`${API_END_POINT}/getByStatus/${status}`),
  search: (body: ISearchWithdrawal, params: Pagination) => {
    return baseService.post<SearchResult<IWithdrawal[]>>(`${API_END_POINT}/searchAll`, body, params);
  },
};

export default withdrawalService;
