import { ICreateEvent, IEvent, IEventResult, ISearchEvent, Pagination, SearchResult } from 'types';
import baseService from './baseService';

const API_END_POINT = '/event';

const eventService = {
  getAll: (body: ISearchEvent, params: Pagination) => {
    return baseService.post<SearchResult<IEvent[]>>(`${API_END_POINT}`, body, params);
  },
  get: (id: number) => baseService.get<IEvent[]>(`${API_END_POINT}/${id}`),
  create: (body: ICreateEvent) => baseService.post(`${API_END_POINT}/create`, body),
  getResult: (id: number) => baseService.get<IEventResult>(`${API_END_POINT}-result/getByEvent/${id}`),
  updateResult: (body: IEventResult) => baseService.post<IEventResult>(`${API_END_POINT}-result`, body),
  changeStatus: (eventId: number, status: number) => baseService.put(`${API_END_POINT}/${eventId}/${status}`),
};

export default eventService;
