import { ToastContainer } from 'react-toastify';
import { HashRouter } from 'react-router-dom';
import Router from 'routers';
import 'react-toastify/dist/ReactToastify.css';
import './App.css';

function App() {
  return (
    <HashRouter>
      <Router />
      <ToastContainer />
    </HashRouter>
  );
}

export default App;
