export enum ActionType {
  create = 'create',
  view = 'view',
  update = 'update',
  reset = 'reset',
  search = 'search',
  approve = 'approve',
  reject = 'reject',
  delete = 'delete',
  copyImage = 'copyImage',
  download = 'download'
}

export enum EventStatus {
  PENDING = 0,
  ACTIVE = 1,
  END = 2,
  REJECTED = -1,
}

export enum BetStatus {
  ACTIVE = 1,
  PENDING = 0,
  REJECTED = -1,
}

export enum Betting1X2Type {
  FULL_TIME = 1,
  HALF_TIME = 0,
}

export enum BettingHandicapType {
  CORNER_KICK_FULL_TIME = 0,
  CORNER_KICK_HALF_TIME = 1,
  HANDICAP_FULL_TIME = 2,
  HANDICAP_HALF_TIME = 3,
  PENALTY_CARD_FULL_TIME = 4,
  PENALTY_CARD_HALF_TIME = 5,
}

export enum BettingOverUnderType {
  TOTAL_GOALS_FULL_TIME = 1,
  TOTAL_GOALS_HALF_TIME = 2,
  TOTAL_CORNER_KICK_FULL_TIME = 3,
  TOTAL_CORNER_KICK_HALF_TIME = 4,
  TOTAL_PENALTY_CARD_FULL_TIME = 5,
  TOTAL_PENALTY_CARD_HALF_TIME = 6,
}

export enum PermissionStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum Method {
  GET = 'Get',
  POST = 'Post',
  PUT = 'Put',
  DELETE = 'Delete',
}

export enum WithdrawalStatus {
  PENDING = 0,
  APPROVED = 1,
  REJECTED = -1,
}

export enum TeamStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum CustomerStatus {
  ACTIVE = 1,
  INACTIVE = 0,
}

export enum BetType {
  WDL = 1,
  HANDICAP = 2,
  OVERUNDER = 3,
  YESNO = 4,
}
