import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';
import {
  ActionType,
  ICreateGroupPermission,
  IGroupPermission,
  ISearchGroupPermission,
  Pagination,
  SearchResult,
} from 'types';
import { formatDate, getLabelByValueStatus, showMessage } from 'utils/helper';
import * as _ from 'lodash';
import groupPermissionService from 'services/groupPermisisonService';
import { PERMISSION_STATUS } from 'utils/constants';

interface GPermissionState extends Pagination {
  groupPermissionList: IGroupPermission[];
  groupPermissionDetail: IGroupPermission;
  groupPermissionAction: ActionType;
  showGroupPermissionModal: boolean;
  searchValues: ISearchGroupPermission;
}

const initialState: GPermissionState = {
  page: 1,
  size: 10,
  total: 0,
  groupPermissionList: [],
  groupPermissionDetail: {} as IGroupPermission,
  groupPermissionAction: ActionType.create,
  showGroupPermissionModal: false,
  searchValues: {},
};

const groupPermissionSlice = createSlice({
  name: 'groupPermission',
  initialState,
  reducers: {
    setPage: (state, action: PayloadAction<GPermissionState['page']>) => {
      state.page = action.payload;
    },
    setSize: (state, action: PayloadAction<GPermissionState['size']>) => {
      state.size = action.payload;
    },
    setGroupPermissionAction: (state, action: PayloadAction<GPermissionState['groupPermissionAction']>) => {
      state.groupPermissionAction = action.payload;
      state.showGroupPermissionModal = true;
    },
    setShowGroupPermissionModal: (state, action: PayloadAction<GPermissionState['showGroupPermissionModal']>) => {
      state.showGroupPermissionModal = action.payload;
    },
    setSearchValues: (state, action: PayloadAction<GPermissionState['searchValues']>) => {
      state.searchValues = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(
        searchGroupPermissionThunk.fulfilled,
        (state, action: PayloadAction<SearchResult<IGroupPermission[]>>) => {
          state.groupPermissionList = action.payload.result.map((item) => ({
            ...item,
            create_at: formatDate(item.createAt),
            statusText: getLabelByValueStatus(PERMISSION_STATUS, item.status),
          }));
          state.total = action.payload.total;
        }
      )
      .addCase(getGroupPermissionThunk.fulfilled, (state, action: PayloadAction<IGroupPermission>) => {
        state.groupPermissionDetail = action.payload;
      });
  },
});

export const createGroupPermissionThunk = createAsyncThunk(
  'createGroupPermission',
  async (body: ICreateGroupPermission, { dispatch, getState }) => {
    const { groupPermission } = getState() as RootState;
    const response = await groupPermissionService.create(body);
    showMessage.success(response.message);
    dispatch(searchGroupPermissionThunk(groupPermission.searchValues));
    return response.data;
  }
);

export const searchGroupPermissionThunk = createAsyncThunk(
  'searchGroupPermission',
  async (body: ISearchGroupPermission, { dispatch, getState }) => {
    const { groupPermission } = getState() as RootState;
    const { data } = await groupPermissionService.getAll(body, _.pick(groupPermission, ['page', 'size']));
    dispatch(setSearchValues(body));
    return data;
  }
);

export const updateGroupPermissionThunk = createAsyncThunk(
  'updateGroupPermission',
  async (body: ICreateGroupPermission, { getState, dispatch }) => {
    const { groupPermission } = getState() as RootState;
    const { data } = await groupPermissionService.update(body, groupPermission.groupPermissionDetail.id);
    dispatch(searchGroupPermissionThunk(groupPermission.searchValues));
    return data;
  }
);

export const getGroupPermissionThunk = createAsyncThunk('getGroupPermission', async (id: number, { dispatch }) => {
  const { data } = await groupPermissionService.getDetail(id);
  dispatch(setShowGroupPermissionModal(true));
  dispatch(setGroupPermissionAction(ActionType.update));
  return data;
});

export const deleteGroupPermissionThunk = createAsyncThunk(
  'deleteGroupPermission',
  async (id: number, { dispatch, getState }) => {
    const { groupPermission } = getState() as RootState;
    const { data } = await groupPermissionService.delete(id);
    dispatch(searchGroupPermissionThunk(groupPermission.searchValues));
    return data;
  }
);

export const { setPage, setSize, setGroupPermissionAction, setShowGroupPermissionModal, setSearchValues } =
  groupPermissionSlice.actions;
const { reducer: permissionReducer } = groupPermissionSlice;
export default permissionReducer;
