import queryString from 'query-string';
import { Response } from 'types';
import { API_SUCCESS_STATUS } from 'utils/constants';
import { showMessage } from 'utils/helper';
import interceptAuth from './axiosClient';

const instanceDownloadFile = interceptAuth('application/json', 'blob');
const instanceFormData = interceptAuth('multipart/form-data');
const instance = interceptAuth();

const baseService = {
  get: async <T = any>(url: string = '', params?: Record<string, any>): Promise<Response<T>> => {
    if (params) {
      url += '?' + queryString.stringify(params);
    }
    try {
      const { data } = await instance.get<Response<T>>(url);
      return data;
    } catch (error: any) {
      const { message } = error.response.data;
      showMessage.error(message);
      throw new Error(message);
    }
  },

  post: async <R = any, T = any>(url: string = '', body?: T, params?: Record<string, any>): Promise<Response<R>> => {
    if (params) {
      url += '?' + queryString.stringify(params);
    }
    try {
      const { data } = await instance.post<Response<R>>(url, body);
      return data;
    } catch (error: any) {
      const { message } = error.response.data;
      showMessage.error(message);
      throw new Error(message);
    }
  },

  put: async <R = any, T = any>(url: string = '', body?: T, params?: Record<string, any>): Promise<Response<R>> => {
    if (params) {
      url += '?' + queryString.stringify(params);
    }
    try {
      const { data } = await instance.put<Response<R>>(url, body);
      const { message, status } = data;
      if (status === API_SUCCESS_STATUS) {
        showMessage.success(message);
      }
      return data;
    } catch (error: any) {
      const { message } = error.response.data;
      showMessage.error(message);
      throw new Error(message);
    }
  },

  delete: async <T = null>(url: string = '', params?: Record<string, any>): Promise<Response<T>> => {
    if (params) {
      url += '?' + queryString.stringify(params);
    }
    try {
      const { data } = await instance.delete<Response<T>>(url);
      const { message, status } = data;
      if (status === API_SUCCESS_STATUS) {
        showMessage.success(message);
      }
      return data;
    } catch (error: any) {
      const { message } = error.response.data;
      showMessage.error(message);
      throw new Error(message);
    }
  },

  downloadFile: (url: string = '', fileName: string = '') =>
    new Promise(async (resolve, reject) => {
      await instanceDownloadFile
        .get(url)
        .then((response) => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();

          resolve(response.data);
        })
        .catch(async (error) => {
          const responseObj = await error.response.data.text();
          showMessage.error(JSON.parse(responseObj).message);
        });
    }),

  postFile: async <T = null>(url: string = '', body: FormData, params?: Record<string, any>): Promise<Response<T>> => {
    if (params) {
      url += '?' + queryString.stringify(params);
    }
    try {
      const { data } = await instanceFormData.post<Response<T>>(url, body);
      return data;
    } catch (error: any) {
      const { message } = error.response.data;
      showMessage.error(message);
      throw new Error(message);
    }
  },

  putFile: async <T = null>(url: string = '', body: FormData, params?: Record<string, any>): Promise<Response<T>> => {
    if (params) {
      url += '?' + queryString.stringify(params);
    }
    try {
      const { data } = await instanceFormData.put<Response<T>>(url, body);
      return data;
    } catch (error: any) {
      const { message } = error.response.data;
      showMessage.error(message);
      throw new Error(message);
    }
  },

};

export default baseService;
