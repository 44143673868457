import { ConfigProviderProps } from 'antd/es/config-provider';

const theme: ConfigProviderProps['theme'] = {
  token: {
    colorPrimary: '#2A95BF',
    fontFamily: `'Poppins', sans-serif`,
    colorBgLayout: '#e3e1e1',
  },
  components: {
    Menu: {
      radiusItem: 0,
    },
    Table: {},
  },
};

export default theme;
