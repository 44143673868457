import { ConfigProviderProps } from 'antd/es/config-provider';

/* eslint-disable no-template-curly-in-string */
const form: ConfigProviderProps['form'] = {
  validateMessages: {
    required: '${label} is required',
    types: {
      email: '${label} is not a valid ${type}',
    },
    string: {
      max: '${label} cannot be longer than ${max} characters',
      len: '${label} must be exactly ${len} in length',
    },
  },
};

export default form;
